<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_3_3") }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <section class="blog-area pb-70">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-12 animated fadeInUp"
              v-for="(item, index) in videoList"
              :key="index"
            >
              <div
                class="card act-card-hover"
                @click="showModel(item.id.videoId)"
              >
                <div class="row no-gutters">
                  <div class="col-md-3">
                    <img
                      :src="item.snippet.thumbnails.medium.url"
                      alt="Image"
                      class="rounded"
                    />
                  </div>
                  <div class="col-md-9">
                    <div class="card-body">
                      <p class="card-text">
                        <span class="text-muted">{{
                          item.snippet.publishTime
                        }}</span>
                      </p>
                      <h5 class="card-title" v-html="item.snippet.title"></h5>
                      <p class="card-text">{{ item.snippet.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div :class="['row', 'text-center']" v-show="isShowNextPage">
        <div class="col-lg-12 read-more">
          <button class="btn btn-warning" @click="getNextList">
            {{ $t("pageBtn") }}<i class="fas fa-chevron-right ml-2"></i>
          </button>
        </div>
      </div>
    </div>
    <VideoModel :videoId="selectVideoId" />
  </div>
</template>

<script>
import VideoModel from "components/DefaultHome/KECC/Common/VideoModel";
import DateFormat from "dateformat";
const apiUrl = `${process.env.VUE_APP_API_URL}/google`;
export default {
  name: "AlbumBody",
  props: {
    title: String,
  },
  components: {
    VideoModel,
  },
  data() {
    return {
      isShowNextPage: true,
      selectVideoId: "",
      videoList: [],
      page: 1,
      limit: 8,
    };
  },
  async mounted() {
    this.getVideoList();
  },
  methods: {
    async showModel(videoId) {
      this.selectVideoId = videoId;
      this.$root.$emit("bv::show::modal", "modal-video");
    },
    getNextList() {
      this.page = this.page + 1;
      this.getVideoList();
    },
    async getVideoList() {
      let youtubeJson = {
        channel_id: "UC-N1zbVArVRQbBYV--MMHpg",
        page: this.page,
        limit: this.limit,
      };

      let api = `${apiUrl}/youtube_list`;
      await this.axios.post(api, youtubeJson).then((response) => {
        let pageNumber = response["data"]["count"]["all"];
        if (this.page == 1) {
          this.items = [];
        }
        response.data.list.forEach((element) => {
          element.snippet.publishTime = DateFormat(
            new Date(Date.parse(element.snippet.publishTime)),
            "yyyy-mm-dd"
          );
        });
        this.videoList = this.videoList.concat(response.data.list);
        if (parseInt(pageNumber) / this.limit <= this.page) {
          this.isShowNextPage = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.is-show-next-page {
  display: none;
}

.card-footer {
  color: #b5bac1;
}

.card-body {
  min-height: 150px;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.in-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.blog-area {
  margin-top: 30px;
}

.card {
  margin-top: 30px;
}

.act-card-hover {
  border-radius: 8x;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.5s;
}

.act-card-hover:hover {
  transform: scale(1.02, 1.02);
}

.card img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.badge {
  font-weight: 500;
}

.fa-eye {
  padding-top: 5px;
  float: right;
}

.read-more {
  border-top: 1px solid #ced4da;
  padding-top: 25px;
}

.fa-eye::before {
  margin-right: 5px;
}

.badge-info {
  padding: 5px 8px;
  margin-right: 4px;
  font-size: 14px;
}
</style>
