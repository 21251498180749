<template>
    <div>
  <b-modal id="modal-video" size="lg" centered hide-header>
 <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" width="100%" :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`" frameborder="0" allowfullscreen allow="autoplay"></iframe>
</div>  
  </b-modal>       

    </div>
    <!--
        <b-modal id="modal-video" size="lg" centered hide-header>
            <div class="modal-body">
                <iframe width="80%" height="80%"  src="https://www.youtube.com/embed/IFTZtuXKzFs" frameborder="0" allowfullscreen></iframe>
                <a class="modal-close js-modal-close">X</a>
            </div>
        </b-modal>  
        -->  
</template>

<script>
    export default {
        name: 'VideoModel',
        props: {
            videoId : String
        },
        computed: {
            getNewsData() {
                let date = this.NewsModelList.post_date.split('-')
                let year = date[0]
                let month = date[1]
                let day = date[2].split(' ')[0].substring(0,2)
                return [year, month, day]
            }
        },
        methods: {
            toggleModal() {
                this.$root.$emit('bv::toggle::modal', 'modal-video')
            },

        }
    }
</script>

<style scoped>
.modal-dialog {
      max-width: 800px;
      margin: 30px auto;
  }
.modal-body {
  position:relative;
  padding:0px;
}
.close {
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}
</style>