var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "solutions-area section-width ptb-100" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_3_3")))]),
          _c("p", [
            _vm._v(
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
            )
          ])
        ]),
        _c("section", { staticClass: "blog-area pb-70" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.videoList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-lg-12 animated fadeInUp" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card act-card-hover",
                        on: {
                          click: function($event) {
                            return _vm.showModel(item.id.videoId)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row no-gutters" }, [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("img", {
                              staticClass: "rounded",
                              attrs: {
                                src: item.snippet.thumbnails.medium.url,
                                alt: "Image"
                              }
                            })
                          ]),
                          _c("div", { staticClass: "col-md-9" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("p", { staticClass: "card-text" }, [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(_vm._s(item.snippet.publishTime))
                                ])
                              ]),
                              _c("h5", {
                                staticClass: "card-title",
                                domProps: {
                                  innerHTML: _vm._s(item.snippet.title)
                                }
                              }),
                              _c("p", { staticClass: "card-text" }, [
                                _vm._v(_vm._s(item.snippet.description))
                              ])
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowNextPage,
                expression: "isShowNextPage"
              }
            ],
            class: ["row", "text-center"]
          },
          [
            _c("div", { staticClass: "col-lg-12 read-more" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning",
                  on: { click: _vm.getNextList }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("pageBtn"))),
                  _c("i", { staticClass: "fas fa-chevron-right ml-2" })
                ]
              )
            ])
          ]
        )
      ]),
      _c("VideoModel", { attrs: { videoId: _vm.selectVideoId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }