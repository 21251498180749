<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'press'" :bannerTitle="bannerTitle" :breadCrumb="breadCrumb" />
        <VideoBody :title="bannerTitle" />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import VideoBody from './VideoBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'VideoMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_3_3"),
            }
        },
        components: {
            Navbar,
            Header,
            VideoBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: this.$t('navbar.menu_3_3'),
                breadCrumb: [{
                    name: this.$t('navbar.menu_3')
                }]
            }
        },
    }
</script>

<style scoped>
    .bg-color {
        background-color: #eff4f5;
    }
</style>