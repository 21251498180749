var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-video",
            size: "lg",
            centered: "",
            "hide-header": ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "embed-responsive embed-responsive-16by9" },
            [
              _c("iframe", {
                staticClass: "embed-responsive-item",
                attrs: {
                  width: "100%",
                  src:
                    "https://www.youtube.com/embed/" +
                    _vm.videoId +
                    "?autoplay=1",
                  frameborder: "0",
                  allowfullscreen: "",
                  allow: "autoplay"
                }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }